
import BaseTextComponent from "@/components/base/el/BaseTextComponent";
import {
  // get_esc_data,
  get_att_data,
  is_object,
  is_empty,
  // flatDeep
} from "@/components/base/utils";

export default {
  name: "BaseMenuComponent",
  mixins: [BaseTextComponent],
  render(createElement) {
    return this.make_element_tree(createElement, this.component_data);
  },
  data() {
    return {
      is_text: false,
      can_drag: false,
      items: []
    };
  },
  created() {
    if (this.component_data.attr['items']) {
      this.items = JSON.parse(this.component_data.attr['items'].replaceAll("'", '"'))
    }
  },
  computed: {
    // arrMenu () {
    //   return flatDeep(this.items, Infinity)
    // }
  },
  watch: {
  },
  methods: {
    addItem(item) {
      this.items.push(item)
    },
    editItem(new_value, path) {
      let path_items = path.reduce((accumulator, currentValue, i) => {
        if (i === 0) {
          return [currentValue]
        } else {
          return [...accumulator,'children',currentValue]
        }
      }, [])
      this.lodash.setWith(this.items, path_items, new_value, Object)
      this.items = JSON.parse(JSON.stringify(this.items))
      return new_value
    },
    deleteItem(path) {
      
      let indexPath = path[path.length - 1]
      
      if (path.length === 1) {
        this.items.splice(indexPath ,1)
      } else {
        let path_items = path.reduce((accumulator, currentValue, i) => {
          if (i === 0) {
            return [currentValue]
          } else {
            return [...accumulator,'children',currentValue]
          }
        }, [])
        path_items.pop()

        console.log(path_items);
        
        console.log(this.lodash.get(this.items, path_items));
        this.lodash.get(this.items, path_items).splice(indexPath ,1)
      }
    },
    save() {
      this.component_data.attr['items'] = JSON.stringify(this.items)
    },
    focus_out() {
    },
    make_element_tree(createElement, element_data, options = {}, context = null) {
      let data = {}
      let el, props, attrs, style, children

      let clone_element_data = JSON.parse(JSON.stringify(element_data))

      if (element_data.attr && 'deepsel-menu' in element_data.attr) {
        let class_array = [{ "deepsel-focus": this.is_editing }]
        if (this.component_data.attr && this.component_data.attr.class) {
          let class_value

          if (Array.isArray(this.component_data.attr.class)) {
            class_value = this.component_data.attr.class.join(" ")
          } else class_value = this.component_data.attr.class

          class_array.push(class_value)
        }
        data = {
          class: class_array,
          attrs: {
            contenteditable: false,
            draggable: false,
          },
          style: this.styling,
          on: {
            click: this.on_click,
            ...this.$store.state.edit_mode ? {
              mouseenter: this.mouse_enter,
              mousedown: this.mouse_down,
            } : {}
          }
        }
      }

      [el, props] = this.get_el_props(clone_element_data, context)
      attrs = this.get_attrs(clone_element_data, context)
      children = this.get_children(clone_element_data, createElement, context)
      style = this.get_style(clone_element_data)

      if (props) data.props = props
      if (attrs) data.attrs = { ...attrs, ...data.attrs }
      if (style) data.style = { ...style, ...data.style }

      for (let key in options) data[key] = options[key]

      return createElement(
        el,
        data,
        children
      )
    },
    get_el_props(element_data, context) {
      let el = element_data.tag
      let props = null
      if (element_data.attr && 'deepsel-menu-item' in element_data.attr) {
        element_data = context.element_data
      }
      return [el, props]
    },
    get_attrs(element_data, context) {
      let attrs = {}
      if (element_data.attr) {
        // if (element_data.attr.scoped) console.log(element_data.attr)

        for (let key in element_data.attr) {
          if (key === 'style' && is_object(element_data.attr[key])) continue
          if (key === 'type' && element_data.tag === 'button') element_data.attr[key] = 'button'
          let value = element_data.attr[key];
          attrs[key] = Array.isArray(value) ? value.join(" ") : value
        }

        if ('deepsel-menu-item-link' in attrs && context && context.deepsel_menu) {
          attrs.href = context.deepsel_menu.link || '#'
          // if(this.$store.state.edit_mode) delete attrs.href  // disable in edit_mode
        }

        if ('deepsel-menu-item-icon' in attrs && context && context.deepsel_menu) {
          attrs.class = context.deepsel_menu.icon
          // if(this.$store.state.edit_mode) delete attrs.href  // disable in edit_mode
        }


        let att_data = get_att_data(element_data, this.$store.state.common_data)
        if (att_data) {
          for (let key in att_data) {
            attrs[key] = att_data[key]
          }
        }

        this.replace_origin_urls(attrs, element_data)

        return attrs
      }
    },
    get_style(element_data) {
      if (element_data.attr && is_object(element_data.attr.style)) {
        let origin = this.$store.state.origin;
        let style = element_data.attr.style
        if (style.backgroundImage) {
          if (
            !style.backgroundImage.includes("https://") &&
            !style.backgroundImage.includes("http://")
          ) {
            let background_img_path = style.backgroundImage
              .replace('url(', '')
              .replace(')', '')
              .replace(/'/g, '')

            // background_img_path = background_img_path.replace(/\\/g, " ")
            if (background_img_path.slice(0, 1) !== "/") {
              background_img_path = "/" + background_img_path;
            }

            style.backgroundImage = `url(${origin + background_img_path})`
          }
        }
        return style
      } else return null
    },
    replace_origin_urls(attrs, element_data) {
      let origin = this.$store.state.origin;
      if (origin) {
        if (element_data.tag === "script" || element_data.tag === "img") {
          if (attrs.src) {
            if (
              !attrs.src.includes("https://") &&
              !attrs.src.includes("http://")
            ) {
              attrs.src = attrs.src.replace(/\\/g, "/");
              if (attrs.src.slice(0, 1) !== "/") {
                attrs.src = "/" + attrs.src;
              }
              attrs.src = origin + attrs.src;
            }
          }
        } else if (element_data.tag === "link") {
          if (
            !attrs.href.includes("https://") &&
            !attrs.href.includes("http://") &&
            attrs.href !== '#' &&
            !attrs.href.includes('javascript:void')
          ) {
            attrs.href = attrs.href.replace(/\\/g, "/");
            if (attrs.href.slice(0, 1) !== "/") {
              attrs.href = "/" + attrs.href;
            }
            attrs.href = origin + attrs.href;
          }
        }
      }
    },
    get_children(element_data, createElement, context) {
      let children = []
      let text_data = null
      let deepsel_menu_item = false

      if (!is_empty(element_data.attr) && element_data.attr && context?.deepsel_menu) {
        if ('deepsel-menu-item-text' in element_data.attr) text_data = context.deepsel_menu.text
      }

      if (element_data.child) {
        for (let item of element_data.child) {
          if (item.node === "element") {
            if (deepsel_menu_item) continue
            if (is_object(item.attr) && !is_empty(item.attr) && 'deepsel-menu-toggle-child' in item.attr && context?.deepsel_menu?.children?.length === 0) {
              continue
            }
            if (is_object(item.attr) && !is_empty(item.attr) && 'deepsel-menu-item' in item.attr) {
              deepsel_menu_item = true

              let items = context && context.deepsel_menu ? context.deepsel_menu.children : this.items

              for (let item_menu of items) {
                children.push(this.make_element_tree(
                  createElement,
                  context && context.element_data ? context.element_data : item,
                  {},
                  { deepsel_menu: item_menu, element_data: context && context.element_data ? context.element_data : item }))
              }
            } else {
              children.push(this.make_element_tree(createElement, item, {}, {
                deepsel_menu: context ? context.deepsel_menu : '',
                element_data: context ? context.element_data : '',
              }));
            }
          } else if (item.node === "text") children.push(text_data !== null ? text_data : item.text)
        }
      }
      return children
    },
  },
}